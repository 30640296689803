/* storybook-check-ignore */
import { useEffect, useRef, useState } from 'react';

import { Box } from '@opendoor/bricks-next';

import { is3PExclusive } from 'components/marketplace/helpers';
import { FooterCta, PropertyDetailsType, SimilarListings } from 'components/marketplace/property';

import { useCustomerToursContext } from '../context/customerToursContext';
import { PropertyCTAs } from './PropertyCTAs';
import { PropertyDetails } from './PropertyDetails';
import { PropertyStatus } from './PropertyStatus';
import { PropertySummary } from './PropertySummary';

type PropertySectionProps = {
  property: PropertyDetailsType;
};

const selfTourConfig = { kind: 'selfTour' as const };

export const PropertySection = ({ property }: PropertySectionProps) => {
  const { tours, loading } = useCustomerToursContext();

  const columnRef = useRef<HTMLElement>(null);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].borderBoxSize[0].inlineSize);
    });

    if (columnRef.current) {
      observer.observe(columnRef.current);
    }

    return () => {
      columnRef.current && observer.unobserve(columnRef.current);
    };
  }, [columnRef.current]);

  const scheduledTour = !!tours.find(
    (tour) =>
      tour.state === 'PENDING' ||
      tour.state === 'PENDING_VERIFICATION' ||
      tour.state === 'SCHEDULED',
  );

  const is3PE = is3PExclusive(property);

  return (
    <Box
      ref={columnRef}
      flexDirection="column"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      $largerThanSM={{ flexBasis: '50%', mt: 55, overflowY: 'scroll' }}
      $largerThanLG={{ maxWidth: '$736x' }}
    >
      <Box $largerThanSM={{ px: 48 }} $smallerThanMD={{ px: 24 }} pt={24}>
        <PropertyStatus property={property} />
        <PropertySummary property={property} />
        <PropertyCTAs property={property} />
        <PropertyDetails property={property} />
        <Box tag="section">
          <SimilarListings listings={property?.similarProperties?.results || []} />
        </Box>
      </Box>
      {/** Enable the "Self-tour now" CTA for homes that are self-tourable in non-Buy Direct markets (SFO, NYC, DC) */}
      {((!is3PE && property.displayBadge === 'ON_THE_MARKET') || property?.canSelfTour) && (
        <FooterCta config={selfTourConfig} property={property} />
      )}
      {!is3PE &&
        (property.displayBadge === 'TRADITIONAL' || property.displayBadge === 'UNKNOWN') &&
        !property.canSelfTour && (
          <FooterCta config={{ kind: 'tourWithAgent' as const }} property={property} />
        )}
      {is3PE &&
        !loading &&
        property.listingState === 'ON_THE_MARKET' &&
        (scheduledTour ? (
          <FooterCta config={{ kind: 'cancelTour' as const, width }} property={property} />
        ) : (
          <FooterCta config={{ kind: 'scheduleTour' as const, width }} property={property} />
        ))}
    </Box>
  );
};
