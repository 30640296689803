/* storybook-check-ignore */
import { FC, ReactNode, useRef, useState } from 'react';

import { Box, PressableBox } from '@opendoor/bricks-next';

interface FooterCTASlideProps {
  children: ReactNode;
  width: number;
  show: boolean;
  onHide: () => void;
}

const SWIPE_THRESHOLD = 50;

const FooterCTASlide: FC<FooterCTASlideProps> = ({ children, width, show, onHide }) => {
  const slideRef = useRef<HTMLElement>(null);

  const [swipeStartY, setSwipeStartY] = useState(null);

  const handleTouchStart = (e: any) => {
    const touch = e.touches[0];
    setSwipeStartY(touch.clientY);
  };

  const handleTouchEnd = (e: any) => {
    if (swipeStartY === null) {
      return;
    }

    const touch = e.changedTouches[0];
    const swipeDistance = touch.clientY - swipeStartY;

    if (swipeDistance > SWIPE_THRESHOLD) {
      onHide();
    }
    setSwipeStartY(null);
  };

  return (
    <>
      <PressableBox
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        position="fixed"
        width="100vw"
        height="100vh"
        top={0}
        left={0}
        zIndex={8}
        backgroundColor="$backgroundInversePureBlack"
        onPress={onHide}
        opacity={show ? 0.5 : 0}
        style={{
          transition: show ? 'opacity 0.5s' : 'opacity 0.5s, visibility 0.5s',
          visibility: show ? 'visible' : 'hidden',
        }}
      />
      <Box
        ref={slideRef}
        alignItems="center"
        width={width}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        position="fixed"
        zIndex={10}
        bottom={0}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        touchAction="none"
        opacity={show ? 1 : 0}
        style={{
          transition: show
            ? 'opacity 0.5s, transform ease 0.5s'
            : 'opacity 0.5s, transform ease 0.5s, visibility 0.5s',
          visibility: show ? 'visible' : 'hidden',
          transform: show ? '' : `translateY(${slideRef.current?.clientHeight ?? 0}px)`,
        }}
      >
        <PressableBox
          width="100%"
          onPress={onHide}
          aria-label="close slideout"
          analyticsName="cosmos-close-pdp-slideout"
        >
          <Box
            width="$88x"
            height="$8x"
            borderRadius="$full"
            opacity={0.72}
            backgroundColor="$backgroundPrimary"
            marginVertical="$4x"
          />
        </PressableBox>
        <Box
          width="100%"
          backgroundColor="$backgroundPrimary"
          borderTopLeftRadius="$12x"
          borderTopRightRadius="$12x"
          paddingTop="$20x"
          paddingHorizontal="$16x"
          paddingBottom="$16x"
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

export default FooterCTASlide;
