/* storybook-check-ignore */
import { FC, useState } from 'react';

import { CONSUMER_URL } from 'components/globals';
import { useTrackProperty } from 'components/marketplace/helpers';
import { TourWithAgentModal } from 'components/marketplace/property/TourWithAgentModal';

import { useObservability } from 'helpers/observability';

import CancelTourSlide from './PropertySection/CancelTourSlide';
import { FloatingCTA } from './PropertySection/FloatingCta';
import TourSelectorSlide from './PropertySection/TourSelectorSlide';
import { PropertyDetailsType } from './types';

type CtaKind = 'selfTour' | 'scheduleTour' | 'cancelTour' | 'tourWithAgent';

type Config =
  | { kind: 'selfTour' }
  | { kind: 'tourWithAgent' }
  | { kind: 'scheduleTour' | 'cancelTour'; width: number };

interface FooterCTAProps {
  config: Config;
  property: PropertyDetailsType;
}

const ACTION_TEXT: Record<CtaKind, string> = {
  selfTour: 'Self-tour now',
  scheduleTour: 'Schedule tour',
  cancelTour: 'Cancel tour',
  tourWithAgent: 'Tour with agent',
};

export const FooterCTA: FC<FooterCTAProps> = ({ config, property }) => {
  const { trackProperty } = useTrackProperty();
  const { trackJourneyEvent } = useObservability();
  const [showSlide, setShowSlide] = useState(false);
  const [isTourWithAgentModalOpen, setIsTourWithAgentModalOpen] = useState(false);

  const handleCtaClick = () => {
    if (config.kind === 'selfTour') {
      trackProperty('property-self-tour', 'marketplace-pdp-footer-cta');
      trackJourneyEvent('marketplaceProperty', 'tourBookingStart', {
        addressUuid: property.addressToken,
        placement: 'footer',
      });

      window.open(
        `${CONSUMER_URL}/dashboard/buy/tour?id=${property.addressToken}`,
        '_blank',
        'noopener',
      );
    } else if (config.kind === 'tourWithAgent') {
      setIsTourWithAgentModalOpen(true);
    } else {
      setShowSlide(true);
    }
  };

  const handleOnHide = () => {
    setShowSlide(false);
  };

  return (
    <>
      <FloatingCTA
        ctaText={ACTION_TEXT[config.kind]}
        onClickCta={handleCtaClick}
        isLiked={property.isLiked}
        onClickReaction={property.onClickReaction}
        variant={config.kind === 'tourWithAgent' ? 'secondary' : 'primary'}
      />
      {config.kind === 'scheduleTour' && (
        <TourSelectorSlide
          property={property}
          width={config.width}
          show={showSlide}
          onHide={handleOnHide}
        />
      )}
      {config.kind === 'cancelTour' && (
        <CancelTourSlide width={config.width} show={showSlide} onHide={handleOnHide} />
      )}
      {config.kind === 'tourWithAgent' && (
        <TourWithAgentModal
          property={property}
          isOpen={isTourWithAgentModalOpen}
          onClose={() => setIsTourWithAgentModalOpen(false)}
        />
      )}
    </>
  );
};
