/* storybook-check-ignore */
import { FC } from 'react';

import { useAuth } from '@opendoor/auth-fe';
import { Text } from '@opendoor/bricks-next';
import { ExclusiveTourTypeSelector } from '@opendoor/cinderblocks/marketplace/ExclusiveTourTypeSelector';
import { TourChoice } from '@opendoor/cinderblocks/marketplace/ExclusiveTourTypeSelector/ExclusiveTourTypeSelectorProps';

import { PropertyDetailsFragment } from '__generated__/athena';

import { getLoginRedirectUrl } from 'components/exclusives/helpers/redirectUnauthenticatedToLogin';
import { getTourRequestPath } from 'components/exclusives/tours/utils';

import { nextAvailableOpenHouse } from '../helpers';
import FooterCTASlide from './FooterCtaSlide';

interface TourSelectorSlideProps {
  property: PropertyDetailsFragment;
  width: number;
  show: boolean;
  onHide: () => void;
}

const TourSelectorSlide: FC<TourSelectorSlideProps> = ({ property, width, show, onHide }) => {
  const { user } = useAuth();

  const nextOpenHouse = nextAvailableOpenHouse(property);

  const onSelectTour = (tour: TourChoice) => {
    if (tour.choice === 'showing') {
      const date = `${tour.date.getMonth() + 1}/${tour.date.getDate()}/${tour.date.getFullYear()}`;
      const path = getTourRequestPath(property.slug, encodeURIComponent(date));
      window.location.href = user ? path : getLoginRedirectUrl(path);
    }

    if (tour.choice === 'schedule') {
      const path = getTourRequestPath(property.slug);
      window.location.href = user ? path : getLoginRedirectUrl(path);
    }
  };

  return (
    <FooterCTASlide width={width} show={show} onHide={onHide}>
      <Text tag="span" typography="$subheaderLarge" mb="$6x">
        Tour this home
      </Text>
      <Text tag="span" typography="$bodySmall" mb="$12x">
        The seller currently lives in this home. Reserve a spot at the open house or schedule a
        tour.
      </Text>
      <ExclusiveTourTypeSelector showing={nextOpenHouse} onSelect={onSelectTour} button="primary" />
    </FooterCTASlide>
  );
};

export default TourSelectorSlide;
