/* storybook-check-ignore */
import { FC } from 'react';

import { Button, Text } from '@opendoor/bricks-next';

import { useCustomerToursContext } from '../context/customerToursContext';
import FooterCTASlide from './FooterCtaSlide';

interface CancelTourSlideProps {
  width: number;
  show: boolean;
  onHide: () => void;
}

const CancelTourSlide: FC<CancelTourSlideProps> = ({ width, show, onHide }) => {
  const { tours, cancelTour, cancelling } = useCustomerToursContext();

  const tour = tours.find(
    (tour) =>
      tour.state === 'PENDING' ||
      tour.state === 'PENDING_VERIFICATION' ||
      tour.state === 'SCHEDULED',
  );

  const onCancelPress = async () => {
    if (tour) {
      await cancelTour(tour.id);
    }

    onHide();
  };

  return (
    <FooterCTASlide width={width} show={show} onHide={onHide}>
      <Text tag="span" typography="$subheaderLarge" mb="$6x">
        Are you sure?
      </Text>
      <Text tag="span" typography="$bodySmall" mb="$12x">
        If you cancel, you’ll need to request additional times to see this home.
      </Text>
      <Button
        analyticsName="cosmos-pdp-slide-cancel-tour-button"
        label="Cancel tour"
        size="medium"
        variant="primary"
        loading={cancelling}
        onPress={onCancelPress}
      />
    </FooterCTASlide>
  );
};

export default CancelTourSlide;
